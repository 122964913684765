import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  z-index: 1;
  position: relative;
  width: 100%;
  min-width: 100vw;
`;

export const MainWrapper = styled.main`
  z-index: 0;
  position: relative;
`;

export const FooterWrapper = styled.footer`
  z-index: 0;
  position: relative;
`;
