import { addDoc, collection } from 'firebase/firestore';
import { firebaseAuth, firestoreDb } from 'lib/clients/firebase';
import {
  createUserWithEmailAndPassword as createUser,
  signInWithEmailAndPassword as signIn,
  sendPasswordResetEmail,
  onAuthStateChanged,
  UserCredential,
  User,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export interface AuthUser {
  uid?: string;
  email?: string | null;
}

export interface FirebaseAuthReturn {
  authUser: AuthUser;
  loading: boolean;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential | null>;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<null | undefined>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

const formatAuthUser = (user: User | null): AuthUser => ({
  uid: user?.uid,
  email: user?.email,
});

const useFirebaseAuth = (): FirebaseAuthReturn => {
  const [authUser, setAuthUser] = useState<AuthUser>({ email: null });
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  const authStateChanged = async (authState: any) => {
    console.log('STATE CHANGE', authState);

    if (!authState) {
      setAuthUser({ email: null }); // questionable whether this is necessary or not
      setLoading(false);
      return;
    }

    setLoading(true);
    const formattedUser = formatAuthUser(authState);
    setAuthUser(formattedUser);
    setLoading(false);

    if (formattedUser.uid) {
      router.push('/dashboard');
    }
  };

  const clear = () => {
    setAuthUser({ email: null });
    setLoading(true);
  };

  const signInWithEmailAndPassword = async (email: string, password: string) =>
    signIn(firebaseAuth, email, password);

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const { user } = await createUser(firebaseAuth, email, password);

    if (!user) return null;

    // get user data from the auth trigger
    const { uid, email: userEmail, displayName } = user;

    // set account  doc
    const account = {
      userUid: uid,
      email: userEmail,
      displayName,
      settings: {},
    };

    try {
      const docRef = await addDoc(collection(firestoreDb, 'users'), account);
      console.log('Document written with ID: ', docRef.id);
    } catch (err) {
      console.error('Error adding document: ', err);
    }

    return null;
  };

  const signOut = () => {
    return firebaseAuth.signOut().then(clear);
  };

  const resetPassword = async (email: string) =>
    sendPasswordResetEmail(firebaseAuth, email);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    resetPassword,
  };
};

export default useFirebaseAuth;
