import { theme } from '../styled/theme';

const transformBp = (bp: string, step?: number) => {
  const num = Number(bp.replace('px', ''));

  if (!step) return `${num}px`;

  const newBp = num + step;

  if (!!newBp && newBp >= 0) return `${newBp}px`;

  return bp;
};

const { breakpoints: bps } = theme;

const sm = transformBp(bps.sm);
const md = transformBp(bps.md);
const lg = transformBp(bps.lg);
const xl = transformBp(bps.xl);
const xxLarge = transformBp(bps.xxl);

export const breakpoints = {
  sm,
  md,
  lg,
  xl,
  '2xl': xxLarge,
  phone: '0px',
  tablet: sm,
  laptop: md,
  desktop: lg,
  largest: xl,
};
