import { theme } from 'styles/styled/theme';

export default {
  variants: {
    primary: {
      color: theme.colors.text.primary,
      bg: theme.colors.primary,
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: '4px',
      height: '45px',
      transition:
        'opacity 0.3s, background-color 0.3s, color 0.3s, box-shadow 0.3s, border 0.3s',
      _disabled: {
        opacity: 0.5,
      },
      _hover: {
        bg: theme.colors.primaryDark,
        border: `1px solid ${theme.colors.primaryDark}`,
        _disabled: {
          opacity: 0.5,
          bg: theme.colors.primaryDark,
        },
      },
    },
    secondary: {
      color: theme.colors.primary,
      bg: 'white',
      border: `1px solid ${theme.colors.primary}`,
      height: '45px',
      transition:
        'opacity 0.3s, background-color 0.3s, color 0.3s, box-shadow 0.3s, border 0.3s',
      _disabled: {
        opacity: 0.5,
      },
      _hover: {
        bg: theme.colors.primary,
        border: `1px solid ${theme.colors.primary}`,
        color: 'white',
        _disabled: {
          opacity: 0.5,
          bg: theme.colors.primary,
        },
      },
    },
  },
};
