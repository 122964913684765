import { Box, Flex } from '@chakra-ui/react';
import { getHref } from 'lib/helpers';
import { Image } from 'components/Image';
import Link from 'next/link';
import { useGlobalQuery } from 'types';
import { Container } from 'components/Layout/Container';
import { useTheme } from 'styled-components';

const Footer = () => {
  const { data } = useGlobalQuery();

  const { colors } = useTheme();

  return (
    <Box bg={colors.accent.beige} pt="80px" py="160px">
      <Container display="flex">
        <Link href="/" passHref>
          <Box
            as="a"
            cursor="pointer"
            mr="auto"
            maxW={200}
            w="100%"
            pos="relative"
          >
            <Image src="/images/logo-dark.png" priority />
          </Box>
        </Link>
        <Flex as="nav" alignItems="center">
          {data?.footer?.navigation?.map((item) => {
            if (!item) return null;

            const href = getHref(item);

            return (
              <Link key={item._key} href={href} passHref>
                <Box as="a" cursor="pointer" padding={15}>
                  {item.label}
                </Box>
              </Link>
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
