export default {
  parts: ['field', 'icon'],
  variants: {
    primary: {
      field: {
        color: 'var(--colors-jet)',
        border: '1px solid var(--colors-geyser)',
        borderColor: 'var(--colors-geyser)',
        bg: 'inherit',
        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
        },
        _disabled: {
          opacity: 0.4,
          backgroundColor: 'rgba(0,0,0,.05)',
          color: 'unset',
          cursor: 'not-allowed',
        },
        _invalid: {
          borderColor: 'var(--colors-error)',
          boxShadow: 'none',
        },
        _focus: {
          zIndex: 1,
          borderColor: 'var(--colors-primary)',
          boxShadow: 'none',
        },
        _placeholder: {
          color: 'var(--colors-geyser)',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
