import {
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from '@chakra-ui/react';
import React from 'react';

const containerSizes = {
  phone: 540,
  tablet: 720,
  laptop: 900,
  desktop: 1100,
  largest: 1260,
};

interface ContainerProps extends ChakraContainerProps {
  children: React.ReactNode;
}

export const Container = (props: ContainerProps) => {
  const { children, ...rest } = props;

  return (
    <ChakraContainer maxW={containerSizes} {...rest}>
      {children}
    </ChakraContainer>
  );
};
