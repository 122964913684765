import useFirebaseAuth, { FirebaseAuthReturn } from 'hooks/useFirebaseAuth';
import { createContext, ReactNode, useContext } from 'react';

const AuthUserContext = createContext<FirebaseAuthReturn>({
  authUser: { email: null },
  loading: true,
  signInWithEmailAndPassword: async () => null,
  createUserWithEmailAndPassword: async () => null,
  resetPassword: async () => undefined,
  signOut: async () => {},
});

interface ProviderProps {
  children: ReactNode;
}

export function AuthUserProvider({ children }: ProviderProps) {
  const auth = useFirebaseAuth();

  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () => useContext(AuthUserContext);
