import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: 'var(--colors-primary)',
    white: 'var(--colors-white)',
    black: 'var(--colors-black)',
    tertiary: 'var(--colors-tertiary)',
    tertiaryDark: 'var(--colors-tertiaryDark)',
    primaryLight: 'var(--colors-primaryLight)',
    primaryDark: 'var(--colors-primaryDark)',
    primaryContrast: 'var(--colors-primaryContrast)',
    danger: 'var(--colors-danger)',
    periwinkleGray: 'var(--colors-periwinkleGray)',
    snow: 'var(--colors-snow)',
    geyser: 'var(--colors-geyser)',
    onyx: 'var(--colors-onyx)',
    jet: 'var(--colors-jet)',
    success: 'var(--colors-success)',
    warning: 'var(--colors-warning)',
    error: 'var(--colors-error)',
    accent: {
      beige: 'var(--colors-beige)',
      slate: 'var(--colors-slate)',
      charcoal: 'var(--colors-charcoal)',
      sky: 'var(--colors-sky)',
    },
    text: {
      primary: '#282828',
      secondary: '#4D4D4D',
      disabled: '#6B6B6B',
      placeholder: '#b9b9b9',
      white: '#FFFFFF',
    },
  },
  fontFamily: {
    heading: 'var(--font-family-heading)',
    body: 'var(--font-family-body)',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    black: 700,
  },
  heading: {
    fontSize: {
      xs: '1.25rem',
      sm: '1.5rem',
      md: '1.75rem',
      lg: '2rem',
      xl: '2.25rem',
      xxl: '2.4rem',
    },
  },
  letterSpacing: {
    normal: 'normal',
    wide: '0.055rem',
  },
  borderRadius: {
    sm: '0.25rem',
    md: '0.33rem',
    lg: '0.5rem',
    xl: '1rem',
  },
  boxShadow: {
    buttonPrimary: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
    inputFocus: '0 0 0 0.2rem rgba(62, 146, 244, 0.5)',
    inputHover: '0 0 0 4px rgba(28, 127, 242, 0.4)',
    navigation: '0 2px 12px 0 rgb(36 50 66 / 8%)',
    dropdown:
      '0 0.063em 0.313em 0 rgba(0,0,0,.07), 0 0.438em 1.063em 0 rgba(0,0,0,.1)',
    dreamy:
      '0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07)',
  },
  padding: {
    xs: '.25rem',
    sm: '.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '3rem',
  },
  margin: {
    xs: '.25rem',
    sm: '.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '3rem',
  },
  breakpoints: {
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xl: '1440px',
    xxl: '1900px',
  },
  navigation: {
    height: 'var(--nav-height)',
  },
  articleContent: {
    maxWidth: '620px',
    baseFontSize: '16px',
  },
};

export { theme };
