import { extendTheme } from '@chakra-ui/react';
import { theme } from 'styles/styled/theme';
import { breakpoints } from './breakpoints';
import Button from './components/button';
import Input from './components/input';
import Select from './components/select';
import Spinner from './components/spinner';

export default extendTheme({
  breakpoints,
  components: {
    Button,
    Spinner,
    Input,
    Select,
  },
  fonts: {
    heading: theme.fontFamily.heading,
    body: theme.fontFamily.body,
  },
  colors: {
    gray: {
      '800': theme.colors.text.primary,
    },
  },
});
