import Footer from 'components/Navigation/Footer';
import Header from 'components/Navigation/Header';
import { ReactNode } from 'react';
import { FooterWrapper, HeaderWrapper, MainWrapper } from './styles';

interface LayoutProps {
  children: ReactNode;
}

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <MainWrapper>{children}</MainWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  );
};

export default DefaultLayout;
