import { Box, Button, Flex } from '@chakra-ui/react';
import { Container } from 'components/Layout/Container';
import { useAuth } from 'context/AuthUserContext';
import { getHref } from 'lib/helpers';
import Image from 'next/image';
import Link from 'next/link';
import { useTheme } from 'styled-components';

import { useGlobalQuery } from 'types';

const Header = () => {
  const { authUser, signOut, loading } = useAuth();
  const { data } = useGlobalQuery();

  const { padding } = useTheme();

  console.log({ authUser, loading });

  return (
    <Container display="flex" py={padding.md}>
      <Link href="/" passHref>
        <Box
          as="a"
          cursor="pointer"
          mr="auto"
          maxW={200}
          w="100%"
          pos="relative"
        >
          <Image
            layout="fill"
            src="/images/logo-dark.png"
            objectFit="contain"
            priority
          />
        </Box>
      </Link>
      <Flex as="nav" alignItems="center">
        {data?.header?.navigation?.map((item) => {
          if (!item) return null;

          const href = getHref(item);

          return (
            <Link key={item?._key} href={href} passHref>
              <Box as="a" cursor="pointer" pt="15px" pb="5px" mx={15}>
                {item?.label}
              </Box>
            </Link>
          );
        })}

        {!authUser.uid && (
          <>
            <Link href="/sign-in" passHref>
              <Button as="a" variant="primary" ml={15}>
                Sign In
              </Button>
            </Link>
            <Link href="/sign-up" passHref>
              <Button as="a" variant="primary" ml={15}>
                Get Started
              </Button>
            </Link>
          </>
        )}
        {authUser.uid && (
          <Button onClick={signOut} variant="primary" ml={15}>
            Sign Out
          </Button>
        )}
      </Flex>
    </Container>
  );
};

export default Header;
