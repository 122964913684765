import NextImage, { ImageProps as NextImageProps } from 'next/image';

interface ImageProps extends Omit<NextImageProps, 'src'> {
  src?: NextImageProps['src'] | null;
  useDefault?: boolean;
}

export const Image = ({ src, useDefault, ...rest }: ImageProps) => {
  if (!src) return null;

  const settings: Pick<
    NextImageProps,
    'layout' | 'objectFit' | 'objectPosition'
  > = {
    layout: rest.layout || 'fill',
    objectFit: rest.objectFit || 'contain',
    objectPosition: rest.objectPosition || 'center',
  };

  if (useDefault) {
    delete settings.layout;
    delete settings.objectFit;
    delete settings.objectPosition;
  }

  return <NextImage src={src} {...settings} {...rest} />;
};
