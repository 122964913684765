import { NavigationItem } from 'types';

export const getHref = (item: NavigationItem) => {
  if (!item.document?.slug && !item.path) {
    return '';
  }

  if (!item?.document?.slug) {
    return item.path?.startsWith('http') ? item.path : `/${item.path}`;
  }

  return `/${item.document?.slug?.current}`;
};
