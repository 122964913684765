import { initAppState, InitAppState } from "context/AppContext/initAppState";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";
import updateState from "update-state";

interface InitContext {
  appState: InitAppState;
  setAppState: any;
}

const AppContext = createContext<InitContext>({
  appState: {},
  setAppState: () => {},
});

export const GlobalContextProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [appState, setAppState] = useState(initAppState);
  const contextState = {
    appState,
    setAppState: (next: unknown) =>
      setAppState((prev) => updateState(prev, next)),
  };

  return (
    <AppContext.Provider value={contextState}>{children}</AppContext.Provider>
  );
};

export const useAppContext = (): InitContext => useContext(AppContext);
