import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import DefaultLayout from 'components/Layout/Default';
import { GlobalContextProvider } from 'context/AppContext';
import { AuthUserProvider } from 'context/AuthUserContext';
import { useApollo } from 'lib/clients/apolloClient';
import { NextComponentType } from 'next';
import { AppProps } from 'next/app';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/styled/theme';
import GlobalStyles from 'styles/styled/globalStyles';
import chakraTheme from 'styles/chakra';

type ExtendedComponent = NextComponentType & {
  Layout?: React.FC;
};

interface ExtendedAppProps extends AppProps {
  Component: ExtendedComponent;
}

export default function App({ Component, pageProps }: ExtendedAppProps) {
  const apolloClient = useApollo(pageProps);

  const Layout = Component.Layout || DefaultLayout;

  return (
    <GlobalContextProvider>
      <AuthUserProvider>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <ChakraProvider theme={chakraTheme} resetCSS={false}>
              <GlobalStyles />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ChakraProvider>
          </ThemeProvider>
        </ApolloProvider>
      </AuthUserProvider>
    </GlobalContextProvider>
  );
}
